import { FaInstagram } from "react-icons/fa";
import { SlSocialVkontakte } from "react-icons/sl";

const socialData = [
  {
    id: 1,
    link: 'https://www.instagram.com/hand_work.diana',
    icon: <FaInstagram  />,
  },
  {
    id: 2,
    link: 'https://vk.com/id705323510',
    icon: <SlSocialVkontakte />,
  },
]

export default socialData;