import React from 'react';
import { Link as LinkScroll } from 'react-scroll';
import LogoImage from '../images/dinalogo.png';

const Logo = () => {
  return (
    <div className='logo'>
      <LinkScroll
        to='home'
        className='logo__link'
        spy={true} 
        smooth={true}
        offset={-50} 
        duration={1000}
      >
        <img className='logo__image' src={LogoImage} alt="logo" /> 
      </LinkScroll>
    </div>
  );
}

export default Logo;