import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useFetch from '../hooks/useFetch';
import Loader from './Loader';
import AlertMessage from './AlertMessage';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "#f2e6d8" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "#f2e6d8" }}
      onClick={onClick}
    />
  );
}

const BeforeAfter = () => {

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    waitForAnimate: false,
  };

  const {data, loading, error} = useFetch("/beforeafternails?populate=*&pagination[pageSize]=100")

  return (
    <section className='before-after'>

      <h2 className='before-after__title'>
        До и после
      </h2>

      <div className='before-after__content'>

        {loading ? (
          <Loader />
        ) : (
          <ul className="before-after__list">
            <Slider {...settings}>
              {data.map((slide) => (
              <li key={slide.id} className="before-after__item">
                <img 
                  className="before-after__image" 
                  src={process.env.REACT_APP_UPLOAD_URL+slide.attributes?.image?.data?.attributes?.url}
                  alt="До и после"
                />
              </li>
              ))}
            </Slider>
          </ul>
        )}

        {error && 
          <AlertMessage 
            message="что-то пошло не так!" 
          />
        }
      </div>
      
    </section>
  );
}

export default BeforeAfter;