import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useFetch from '../hooks/useFetch';
import Loader from './Loader';
import AlertMessage from './AlertMessage';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "#f2e6d8" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "#f2e6d8" }}
      onClick={onClick}
    />
  );
}

const Videos = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  const {data, loading, error} = useFetch("/videos?populate=*&pagination[pageSize]=100")

  return (
    <section className='videos'>
      <h2 className='videos__title'>
        видео
      </h2>

      <div className="videos__content">

        {loading ? (
          <Loader />
        ) : (
          <ul className="videos__list">
            <Slider {...settings}>
              {data.map((slide) => (
                <li 
                  key={slide.id} 
                  className="videos__item"
                >
                  <video 
                    className='videos__video'
                    src={process.env.REACT_APP_UPLOAD_URL+slide.attributes?.video?.data?.attributes?.url}
                    controls
                    muted 
                  >
                    Your browser does not support HTML5 video.
                  </video>
                </li>
            ))}
          </Slider>
        </ul>
        )}

        {error && 
          <AlertMessage 
            message="что-то пошло не так!" 
          />
        }

      </div>
    </section>
  );
}

export default Videos;