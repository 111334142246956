import React from 'react';
import { Link as LinkScroll } from 'react-scroll';
import { motion } from "framer-motion";
import Slideshow from './Slideshow';

const Header = () => {
  return (
    <header className="header" id='home'>
      <div className="header__content"> 

        <Slideshow /> 

        <div className="header__info">
          <motion.h1 
            className='header__title'
            initial={{ opacity: 0, transform: 'scale(0)' }}
            animate={{ opacity: 1, transform: 'scale(1)' }}
            transition={{ ease: "easeInOut", duration: 1,  }}
          >
            Мир <span className='header__title_accent'> красоты </span> на кончиках пальцев!
          </motion.h1>
          <LinkScroll
            to='portfolio'
            className='header__link'
            spy={true} 
            smooth={true}
            offset={-50} 
            duration={1000}
          >
            портфолио
          </LinkScroll>
        </div>
      </div>
    </header>
  );
}

export default Header;