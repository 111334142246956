import React from 'react';
import { GiFlowerTwirl } from 'react-icons/gi';

const Loader = () => {
  return (
    <div className='loader'>
      <GiFlowerTwirl className='loader__icon' />
      <p className='loader__text'>загружается..</p>
    </div>
  );
}

export default Loader;