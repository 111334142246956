import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/dinalogo.png';

const PageNotFound = () => (
  <div className="page-not-found">
    <div className='page-not-found__content'>
      <img className='page-not-found__image' src={Logo} alt="logo" />
      <p className='page-not-found__title'>404 - Страница не найдена</p>
      <Link to="/" className="page-not-found__link">
        Назад
      </Link>
    </div>
  </div>
);

export default PageNotFound;