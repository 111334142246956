import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromFavorite, resetFavorites } from '../redux/favoriteReducer';
import { FaXmark } from "react-icons/fa6";
import { MdOutlineZoomOutMap } from "react-icons/md";
import Popup from "../components/Popup";
import SectionTitle from '../components/SectionTitle';
import { FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import ScrollToTop from '../components/ScrollToTop';
import { useHistory } from "react-router-dom";
import { FaAngleDoubleLeft } from "react-icons/fa";
import CardFav from '../components/CardFav';

const Favorite = () => {
  const cards = useSelector(state => state.favorite.cards);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <section className='favorite'>
      <SectionTitle>избранное</SectionTitle> 

      <div className="favorite__content">
        <nav className="favorite__nav"> 
          <button
            type='button'
            onClick={() => history.goBack()}
            className='favorite__back-link'
          >
            назад
          </button>
        </nav> 
        <div className="favorite__content">
          {!cards.length ? (
            <div className='favorite__message-container'>
              <FaHeart className='icon' /> 
              <p className='favorite__message-text'>Пока пустой!</p>
            </div>
          ) : (
            <ul className="cards">
              {cards?.map((item) => (
                <CardFav
                  key={item.id}
                  item={item}
                />
              ))}
            </ul> 
          )} 
        </div>

        {cards.length ? (
          <button 
            className='favorite__reset-button'
            type="button"
            onClick={() => dispatch(resetFavorites()) }
          >
            Очистить все
          </button>
        ) : (
          <p></p>
        )}

      </div>
    </section>
  );
}

export default Favorite;
   