import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLiked: false,
  complete: false,
  cards: []
}
  
export const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    addToFavorite: (state, action) => {
      const item =  state.cards.find((item) => item.id === action.payload.id);
      
      if (item) {
        item.quantity += action.payload.quantity;
      } else {
        state.cards.push(action.payload);
      }
    },
    toggle: (state, action) => {
      const item =  state.cards.find((item) => item.id === action.payload.id);
      if (item.id === action.payload.id) {
        return { ...item, complete: !item.complete }
      }
      return item
    },
    removeFromFavorite: (state, action) => {
      state.cards = state.cards.filter(item => item.id !== action.payload)
    },
    resetFavorites: (state) => {
      state.cards = []
    },
    setIsLiked: (state, action) => {
      //state.isLiked = !state.isLiked;
      state.isLiked = !state.isLiked;
    },
  }
})

export const { addToFavorite, removeFromFavorite, resetFavorites, setIsLiked, toggle } = favoriteSlice.actions;

export default favoriteSlice.reducer;