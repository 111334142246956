import React from 'react';
import { LuAmpersand } from "react-icons/lu";
import NailCareImage from '../images/intro/nail-care.png';
import ManicureImage from '../images/intro/manicure.png';
import NailArtImage from '../images/intro/nailart.png';
import NailGelImage from '../images/intro/nail-gel.png';
import PedicureImage from '../images/intro/pedicure.png';
import GrowingImage from '../images/intro/growing.png';

const Intro = () => {
  return (
    <section className='intro'>
      <div className="intro__content">
        <ul className="intro__list">
          <li className="intro__item">
            <img className='intro__image' src={ManicureImage} alt="Маникюр" />
            <p className='intro__text'>Маникюр</p>
          </li>
          <li className="intro__item">
            <img className='intro__image' src={NailCareImage} alt="обработка ногтей" />
            <p className='intro__text'>обработка ногтей</p>
          </li>
          <li className="intro__item">
            <img className='intro__image' src={GrowingImage} alt="наращивание " />
            <p className='intro__text'>наращивание </p>
          </li>
          <li className="intro__item">
            <img className='intro__image' src={PedicureImage} alt="педикюр" />
            <p className='intro__text'>педикюр</p>
          </li>
           <li className="intro__item">
            <img className='intro__image' src={NailGelImage} alt="Гелевые ногти" />
            <p className='intro__text'>Гелевые <LuAmpersand /> акриловые ногти</p>
          </li>
          <li className="intro__item">
            <img className='intro__image' src={NailArtImage} alt="Нейл-арт" />
            <p className='intro__text'>Нейл-арт</p>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Intro;