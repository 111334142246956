import React, { useState, useEffect } from 'react';
import Popup from './Popup';
import { MdOutlineZoomOutMap } from "react-icons/md";
import { addToFavorite, toggle } from '../redux/favoriteReducer';
import { useDispatch, useSelector } from 'react-redux';
import { FaHeart } from "react-icons/fa";

const Card = ( {item} ) => {
  
  const [popupActive, setPopupActive] = useState(false);
  const dispatch = useDispatch();
  //const isLiked = useSelector((state) => state.cards.isLiked);

  

  // Popup esc close
  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKey);
      return () => {
        document.removeEventListener("keydown", handleEscapeKey);
      };
  }, [popupActive]);

  function handleEscapeKey(e) {
    if(e.key === 'Escape') {
      setPopupActive(false)
    }   
  }

  return (
    <>
      <li className="card">
        <img 
          className='card__image' 
          src={process.env.REACT_APP_UPLOAD_URL+item.attributes?.image?.data?.attributes?.url} 
          alt="изображения ногтей" 
        />
        <div className="card__overlay" onClick={() => setPopupActive(true)}>
          <p className='card__icon'><MdOutlineZoomOutMap /></p>
        </div> 
        {/* <button 
          className='card__button'
          type="button"
          onClick={() => dispatch(addToFavorite({
            id: item.id,
            image: item.attributes.image.data.attributes.url,
          }))}
        >
          <span className='icon'>
            <FaHeart />
          </span>  
        </button>  */}
      </li>

      <Popup 
        active={popupActive} 
        setActive={setPopupActive} 
      >
        <img 
          className='popup__image' 
          src={process.env.REACT_APP_UPLOAD_URL+item.attributes?.image?.data?.attributes?.url} 
          alt="изображения ногтей" 
        />
      </Popup>
    </>
  );
}

export default Card;