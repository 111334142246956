import React from 'react';
import Nav from '../components/Nav';
import Header from '../components/Header';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import About from '../components/About';
import Treatments from '../components/Treatments';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';
import Testimonials from '../components/Testimonials';
import BeforeAfter from '../components/BeforeAfter';
import Videos from '../components/Videos';

const Homepage = () => {
  return (
    <>
      <Nav /> 
      <Header />
      <Intro /> 
      <Portfolio />
      <BeforeAfter /> 
      <Treatments />
      {/* <Videos />   */}
      <About />
      <Testimonials /> 
      <Contact />
      <Footer />  
      <ScrollToTop />
    </>
  );
}

export default Homepage;