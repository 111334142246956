import React from 'react';

const SectionTitle = ({ children }) =>{
  return (
    <div className='sectionTitle'>
      <h2 className="sectionTitle__title">
        {children}
      </h2>
    </div>
  );
}

export default SectionTitle;