import React from 'react';
import { FaRubleSign } from "react-icons/fa6";
import SectionTitle from './SectionTitle';
import Treat1Image from "../images/treatments/dinintro.jpg"
import Treat2Image from "../images/treatments/dintreat4.jpg"
import Treat3Image from "../images/treatments/dinatreat3.jpg"


const Treatments = () => {
  return (
    <section className='treatments' id='treatments'>
      <SectionTitle>процедуры и цены</SectionTitle> 
      <div className='treatments__content'>

        <div className="treatments__info">
          <img className='treatments__image' src={Treat1Image} alt="картинка для ногтей" />
          <ul className="treatments__list">
            <li className="treatments__item">
              <p className="treatments__treatment">Маникюр</p>
              <p className="treatments__price">600 <FaRubleSign /></p>
            </li>
            <li className="treatments__item">
              <p className="treatments__treatment">Маникюр с покрытием однотонный</p>
              <p className="treatments__price">1000 <FaRubleSign /></p>
            </li>
            <li className="treatments__item">
              <p className="treatments__treatment">Маникюр с укреплением</p>
              <p className="treatments__price">1200 <FaRubleSign /></p>
            </li>
          </ul>
        </div>

        <div className="treatments__info">
          <img className='treatments__image treatments__image_second' src={Treat2Image} alt="картинка для ногтей" />
          <ul className="treatments__list">
            <li className="treatments__item">
              <p className="treatments__treatment">педикюр без покрытия</p>
              <p className="treatments__price">1000 <FaRubleSign /></p>
            </li>
            <li className="treatments__item">
              <p className="treatments__treatment">Педикюр с покрытием</p>
              <p className="treatments__price">1600 <FaRubleSign /></p>
            </li>
            <li className="treatments__item">
              <p className="treatments__treatment">Педикюр с покрытием + полировка стопы</p>
              <p className="treatments__price">1900 <FaRubleSign /></p>
            </li>
            <li className="treatments__item">
              <p className="treatments__treatment">Педикюр без покрытия и  полировка стопы</p>
              <p className="treatments__price">1400 <FaRubleSign /></p>
            </li>
          </ul>
        </div>

        <div className="treatments__info">
          <img className='treatments__image' src={Treat3Image} alt="картинка для ногтей" />
          <ul className="treatments__list">
            <li className="treatments__item">
              <p className="treatments__treatment">Снять другие</p>
              <p className="treatments__price">100 <FaRubleSign /></p>
            </li>
            <li className="treatments__item">
              <p className="treatments__treatment">Ремонт</p>
              <p className="treatments__price">100 <FaRubleSign /></p>
            </li>
            <li className="treatments__item">
              <p className="treatments__treatment">Дизайн</p>
              <p className="treatments__price">50 <FaRubleSign /></p>
            </li>
            <li className="treatments__item">
              <p className="treatments__treatment">Дизайн сложный</p>
              <p className="treatments__price">100 <FaRubleSign /></p>
            </li>
            <li className="treatments__item">
              <p className="treatments__treatment">Френч</p>
              <p className="treatments__price">200 <FaRubleSign /></p>
            </li>
            <li className="treatments__item">
              <p className="treatments__treatment">Втирка</p>
              <p className="treatments__price">200 <FaRubleSign /></p>
            </li>
            <li className="treatments__item">
              <p className="treatments__treatment">Наращивание </p>
              <p className="treatments__price">1800 <FaRubleSign /></p>
            </li>
            <li className="treatments__item">
              <p className="treatments__treatment">Омбре градиент</p>
              <p className="treatments__price">300 <FaRubleSign /></p>
            </li>
          </ul>
        </div>

      </div>
    </section>
  );
}

export default Treatments;