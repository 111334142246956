import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";
import useFetch from '../hooks/useFetch';
import Loader from './Loader';
import AlertMessage from './AlertMessage';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "#f2e6d8" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "#f2e6d8" }}
      onClick={onClick}
    />
  );
}

const Testimonials = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const {data, loading, error} = useFetch("/testimonials?populate=*&pagination[pageSize]=100")

  return (
    <section className='testimonials'>
      <h2 className='testimonials__title'>отзывы</h2>

      <div className='testimonials__content'>

        {loading ? (
          <Loader />
        ) : (
          <ul className="testimonials__list">
            <Slider {...settings}>
              {data.map((slide) => (
                <li key={slide.id} className="testimonials__item">
                  <div className="testimonials__image-container">
                    <img 
                      className='testimonials__image' 
                      src={process.env.REACT_APP_UPLOAD_URL+slide.attributes?.image?.data?.attributes?.url}
                      alt="отзывы" 
                    />
                  </div>
                  <div className="testimonials__info">
                    <p className="testimonials__text">
                      <sup><FaQuoteLeft className='icon icon_left'/></sup>
                        {slide?.attributes.text}
                      <sup><FaQuoteRight className='icon icon_right'/></sup>
                    </p>
                    <p className='testimonials__name'>
                      {slide?.attributes.name}
                    </p>
                  </div>
                </li>
              ))}
            </Slider>
          </ul>
        )}

        {error && 
          <AlertMessage 
            message="что-то пошло не так!" 
          />
        }

      </div>
    </section>
  );
}

export default Testimonials;