import React from 'react';
import AboutImage from '../images/about/about.jpg';
import AboutImageNail from '../images/about/treat1.jpg'
import Social from './Social';
import SectionTitle from './SectionTitle';
import LinkButton from './LinkButton';

const About = () => {
  return (
    <section className='about' id='about'>
      <SectionTitle>обо мне</SectionTitle>
      <div className="about__content">

        <div className='about__image-container'>
          <img className='about__image' src={AboutImage} alt="обо мне" />
          <img className='about__image about__image_small' src={AboutImageNail} alt="картинка для ногтей" />
        </div>
        
        <div className="about__text-container">
          <p className="about__text">
            Здравствуйте! Меня зовут <span className='about__text_accent'>Диана</span>. Мой путь в мир маникюра начался
            с увлечения красотой и страсти к творчеству. За эти годы я приобрела
            навыки через обширное обучение и практический опыт.
          </p>
          <p className="about__text">
            Выбор мастера по маникюру — это больше, чем просто услуга; это выбор 
            человека, который понимает ваш стиль и заботится о здоровье ваших ногтей
            так же, как и вы. Я горжусь созданием теплой и дружелюбной атмосферы, где
            вы можете расслабиться и насладиться заслуженным уходом. Я внимательно слушаю
            ваши потребности и работаю вместе с вами, чтобы каждый раз создавать идеальный образ.
          </p>
          <div className="about__contact-container">
            <LinkButton link='contact'>
              контакты
            </LinkButton>
            <Social />
          </div>

        </div>
      </div>
    </section>
  );
}

export default About;