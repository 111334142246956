import React from 'react';
import { Link } from 'react-router-dom';
import socialData from '../data/socialData';

const Social = () => {
  return (
    <div className="social">
      <ul className="social__list">

        {socialData.map((item) => {
          return (
            <li 
              key={item.id}
              className='social__item'
            >
              <Link 
                className='social__link'
                to={{ pathname: `${item.link}` }}
                target="_blank"
              >
                {item.icon}
              </Link>
            </li>
          ) 
        })} 
        
      </ul>
    </div>
  );
}

export default Social;