import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor,store } from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import App from '../src/components/App';
import './styles/sass/main.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading="loading" persistor={persistor}>
      <BrowserRouter> 
        <App />
      </BrowserRouter> 
    </PersistGate>
  </Provider>
);
