import React from 'react';
import { Link as LinkScroll } from 'react-scroll';

const LinkButton = ({ children, link }) => {
  return (
    <LinkScroll
      to={link}
      className='link-button'
      spy={true} 
      smooth={true}
      offset={-50} 
      duration={1000}
    >
      {children}
    </LinkScroll>
  );
}

export default LinkButton;