import React from 'react';
import { FaRegSadTear } from 'react-icons/fa';

const AlertMessage = ({ message }) => {
  return (
    <div className='alert-message'>
      <p className='alert-message__icon'><FaRegSadTear /></p>
      <p className='alert-message__text'>{message}</p>
    </div>
  );
}

export default AlertMessage;