import React, { useState, useEffect } from 'react';
import { FaChevronUp } from "react-icons/fa";

const ScrollToTop = () => {
  const [showTopButton, setShowTopButton] = useState(false);
  const windowHeight = 300;

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > windowHeight) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    });
  }, []);

  const handleToTopButton = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className='scrollToTop'>
      <button 
        type='button'
        className={showTopButton ? 'scrollToTop__button' : ''}
        onClick={handleToTopButton}
      >
        <FaChevronUp className='icon' />
      </button> 
    </div>
  );
}
export default ScrollToTop;