import React, { useState, useEffect } from 'react';
import SectionTitle from './SectionTitle';
import Cards from './Cards';

const Portfolio = ({className}) => {
  const [popupActive, setPopupActive] = useState(false);

  // Popup esc close
  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKey);
      return () => {
        document.removeEventListener("keydown", handleEscapeKey);
      };
  }, [popupActive]);

  function handleEscapeKey(e) {
    if(e.key === 'Escape') {
      setPopupActive(false)
    }   
  }
  
  return (
    <section className='portfolio' id='portfolio'>
      <SectionTitle>портфолио</SectionTitle>
      <div className="portfolio__content">
        <Cards />
      </div>
    </section>
  );
}

export default Portfolio;