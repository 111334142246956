import React, {useState, useEffect} from 'react';
import { Link as LinkScroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import { FaHeart } from "react-icons/fa";
import navLinks from '../data/navLinks';
import Logo from './Logo';
import Hamburger from './Hamburger';
import Social from './Social';



const Nav = () => {
  const [sidebarActive, setSidebarActive] = useState(false);
  const [fixedNav, setFixedNav] = useState(false);
  const windowHeight = 100;

  // add fixed nav
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY >= windowHeight) {
        setFixedNav(true);
      } else {
        setFixedNav(false);
      }
    });
  }, []);

  // LOCK BODY
  useEffect(() => {
    if (sidebarActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
     }
  }, [sidebarActive]);
  
  return (
    <nav className={fixedNav ? 'nav nav_fixed' : 'nav'}>
      <div 
        className={sidebarActive ? "nav__overlay active" : "nav__overlay"}
        onClick={() => setSidebarActive(!sidebarActive)}
      >
      </div>
      <div className="nav__content">
        <div className="nav__header">
          <Logo />
          <div className="nav__hamburger-container"> 
            <Social />
            <div className="nav__hamburger-favorite-container">
              {/* <Link to="/favorite" className='nav__link-favorite'>
                <FaHeart className='icon' />
              </Link> */}
              <Hamburger 
                sidebarActive={sidebarActive}
                onClick={() => setSidebarActive(!sidebarActive)}
              />
            </div>
          </div>
        </div>

        <div className={sidebarActive ? "nav__list-container active" : "nav__list-container"}>
         
          <ul className='nav__list'>
            {navLinks.map((link) => {
              return (
                <li 
                  className="nav__item"
                  key={link.id}
                >
                  <LinkScroll
                    to={link.section}
                    className="nav__link" 
                    activeClass="nav__link_active"
                    spy={true} 
                    smooth={true}
                    offset={-20} 
                    duration={1000}
                    onClick={() => setSidebarActive(!sidebarActive)}
                  >
                    <span className='icon'>{link.icon} </span>
                    {link.text}
                  </LinkScroll>
                </li>
              )
            })}
          </ul>
          
        </div>
      </div>
    </nav>
  );
}

export default Nav;