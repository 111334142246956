import React, { useState, useEffect } from 'react';
import { FaXmark } from "react-icons/fa6";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { removeFromFavorite, resetFavorites } from '../redux/favoriteReducer';
import { useDispatch, useSelector } from 'react-redux';
import Popup from './Popup';

const CardFav = ({item}) =>{

  const dispatch = useDispatch();
  const [popupActive, setPopupActive] = useState(false);

  // Popup esc close
  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKey);
      return () => {
        document.removeEventListener("keydown", handleEscapeKey);
      };
  }, [popupActive]);

  function handleEscapeKey(e) {
    if(e.key === 'Escape') {
      setPopupActive(false)
    }   
  }

  return (
    <>
      <li className='card' key={item.id}>
        <img 
          className='card__image'
          src={process.env.REACT_APP_UPLOAD_URL+item.image} 
          alt="изображения ногтей" 
        />
        <div className="card__overlay" onClick={() => setPopupActive(true)}>
          <p className='card__icon'><MdOutlineZoomOutMap /></p>
        </div> 
        <button 
          className='card__button'
          type="button"
          onClick={() => dispatch(removeFromFavorite(item.id)) }
        >
          <span className='icon'><FaXmark /></span>
        </button>
      </li>

      <Popup 
        active={popupActive} 
        setActive={setPopupActive} 
      >
        <img 
          className='popup__image' 
          src={process.env.REACT_APP_UPLOAD_URL+item.image} 
          alt="изображения ногтей" 
        />
      </Popup>
    </>
  );
}

export default CardFav;