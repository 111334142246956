import { GoPerson } from "react-icons/go";
import { MdMailOutline, MdOutlineReviews } from "react-icons/md";
import { FaServicestack, FaThLarge, FaHome, FaVideo  } from "react-icons/fa";
import { CgArrowsBreakeH } from "react-icons/cg";

const navLinks = [
  {
    id: 1,
    section: 'home',
    text: 'главная',
    icon: <FaHome  />,
  },
  {
    id: 2,
    section: 'portfolio',
    text: 'портфолио',
    icon: <FaThLarge />,
  },
  {
    id: 3,
    section: 'before-after',
    text: "До и после",
    icon: <CgArrowsBreakeH />,
  },
  {
    id: 4,
    section: 'about',
    text: "обо мне",
    icon: <GoPerson />,
  },
  {
    id: 5,
    section: 'testimonials',
    text: 'отзывы',
    icon: <MdOutlineReviews />,
  },
  {
    id: 6,
    section: 'treatments',
    text: 'процедуры и цены',
    icon: <FaServicestack />,
  },
  {
    id: 7,
    section: 'videos',
    text: 'видео',
    icon: <FaVideo />,
  },
  {
    id: 8,
    section: 'contact',
    text: 'контакты',
    icon: <MdMailOutline />,
  },
]

export default navLinks;