import { MdOutlineAlternateEmail } from "react-icons/md";
import {  FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

const contactData = [
  {
    id: 1,
    text: '+7 932 014 54 41',
    icon: <FaPhoneAlt  />,
  },
  {
    id: 2,
    text: 'diana.nail.stylist@gmail.com',
    icon: <MdOutlineAlternateEmail />,
  },
  {
    id: 3,
    text: 'Г.Челябинск, Курчатовский район, Краснопольский проспект',
    icon: <FaLocationDot />,
  },
]

export default contactData;